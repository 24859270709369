// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-access-and-coverage-js": () => import("./../src/pages/access-and-coverage.js" /* webpackChunkName: "component---src-pages-access-and-coverage-js" */),
  "component---src-pages-coronary-revascularization-data-js": () => import("./../src/pages/coronary-revascularization-data.js" /* webpackChunkName: "component---src-pages-coronary-revascularization-data-js" */),
  "component---src-pages-cv-outcomes-js": () => import("./../src/pages/cv-outcomes.js" /* webpackChunkName: "component---src-pages-cv-outcomes-js" */),
  "component---src-pages-dosing-js": () => import("./../src/pages/dosing.js" /* webpackChunkName: "component---src-pages-dosing-js" */),
  "component---src-pages-get-vascepa-now-js": () => import("./../src/pages/get-vascepa-now.js" /* webpackChunkName: "component---src-pages-get-vascepa-now-js" */),
  "component---src-pages-getting-patients-started-js": () => import("./../src/pages/getting-patients-started.js" /* webpackChunkName: "component---src-pages-getting-patients-started-js" */),
  "component---src-pages-guidelines-js": () => import("./../src/pages/guidelines.js" /* webpackChunkName: "component---src-pages-guidelines-js" */),
  "component---src-pages-helpful-resources-js": () => import("./../src/pages/helpful-resources.js" /* webpackChunkName: "component---src-pages-helpful-resources-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-outcomes-data-js": () => import("./../src/pages/outcomes-data.js" /* webpackChunkName: "component---src-pages-outcomes-data-js" */),
  "component---src-pages-patient-resources-js": () => import("./../src/pages/patient-resources.js" /* webpackChunkName: "component---src-pages-patient-resources-js" */),
  "component---src-pages-prior-mi-subgroup-data-js": () => import("./../src/pages/prior-mi-subgroup-data.js" /* webpackChunkName: "component---src-pages-prior-mi-subgroup-data-js" */),
  "component---src-pages-quiz-pharmacist-js": () => import("./../src/pages/quiz/pharmacist.js" /* webpackChunkName: "component---src-pages-quiz-pharmacist-js" */),
  "component---src-pages-register-thank-you-js": () => import("./../src/pages/register-thank-you.js" /* webpackChunkName: "component---src-pages-register-thank-you-js" */),
  "component---src-pages-repvisit-js": () => import("./../src/pages/repvisit.js" /* webpackChunkName: "component---src-pages-repvisit-js" */),
  "component---src-pages-resources-hcps-js": () => import("./../src/pages/resources/hcps.js" /* webpackChunkName: "component---src-pages-resources-hcps-js" */),
  "component---src-pages-resources-pharmacists-js": () => import("./../src/pages/resources/pharmacists.js" /* webpackChunkName: "component---src-pages-resources-pharmacists-js" */),
  "component---src-pages-revasc-js": () => import("./../src/pages/revasc.js" /* webpackChunkName: "component---src-pages-revasc-js" */),
  "component---src-pages-safety-js": () => import("./../src/pages/safety.js" /* webpackChunkName: "component---src-pages-safety-js" */),
  "component---src-pages-samples-js": () => import("./../src/pages/samples.js" /* webpackChunkName: "component---src-pages-samples-js" */),
  "component---src-pages-site-map-js": () => import("./../src/pages/site-map.js" /* webpackChunkName: "component---src-pages-site-map-js" */),
  "component---src-pages-the-vascepa-patient-js": () => import("./../src/pages/the-vascepa-patient.js" /* webpackChunkName: "component---src-pages-the-vascepa-patient-js" */),
  "component---src-pages-total-events-analysis-js": () => import("./../src/pages/total-events-analysis.js" /* webpackChunkName: "component---src-pages-total-events-analysis-js" */),
  "component---src-pages-vascepa-difference-js": () => import("./../src/pages/vascepa-difference.js" /* webpackChunkName: "component---src-pages-vascepa-difference-js" */),
  "component---src-pages-why-vascepa-js": () => import("./../src/pages/why-vascepa.js" /* webpackChunkName: "component---src-pages-why-vascepa-js" */)
}

